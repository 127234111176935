import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DemoComponent } from './demo/demo.component';
import { EhmComponent } from './ehm/ehm.component';
import { ICT2018Component } from './ehm/ict2018/ict2018.component';
// import { Postercomp2019Component } from './events/postercomp2019/postercomp2019.component';
import { HomeComponent } from './home/home.component';
import { SixArtsCourseDetailComponent } from './six-arts/six-arts-course-detail/six-arts-course-detail.component';
import { SixArtsComponent } from './six-arts/six-arts.component';
import { SixArtsManagementComponent } from './six-arts/six-arts-management/six-arts-management.component';
import { SixArtsCourseAddComponent } from './six-arts/six-arts-management/six-arts-course-add/six-arts-course-add.component'
import { SixArtsCourseEditComponent } from './six-arts/six-arts-management/six-arts-course-edit/six-arts-course-edit.component'
import { SmartIntComponent } from './smart-int/smart-int.component';
import { TrainingGuidanceDocComponent } from './training/training-guidance-doc/training-guidance-doc.component';
import { TrainingRecordDetailComponent } from './training/training-record-detail/training-record-detail.component';
import { TrainingRecordComponent } from './training/training-record/training-record.component';
import { TrainingSyllabusComponent } from './training/training-syllabus/training-syllabus.component';
import { TrainingComponent } from './training/training.component';
import { CourseComponent } from './course/course.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { HiComponent } from './hi/hi.component';
import { VolunteerComponent } from './volunteer/volunteer.component';
import { ActivityComponent } from './activity/activity.component';
import { AuthComponent } from './auth/auth.component';

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },

  { path: "six-arts", component: SixArtsComponent },
  {
    path: "six-arts/:courseGroupId/:courseId",
    component: SixArtsCourseDetailComponent
  },
  // { path: "six-arts/manage", component: SixArtsManagementComponent },
  { path: "six-arts/add", component: SixArtsCourseAddComponent },
  {
    path: "six-arts/manage/:courseGroupId/:courseId",
    component: SixArtsCourseEditComponent
  },

  // { path: "events/poster-competition-2019", component: Postercomp2019Component },
  { path: "login", component: AuthComponent },

  { path: "training", component: TrainingComponent },
  { path: "training/training-record", component: TrainingRecordComponent },
  {
    path: "training/training-record/:certId",
    component: TrainingRecordDetailComponent
  },
  { path: "training/doc", component: TrainingGuidanceDocComponent },
  { path: "training/:courseId", component: TrainingSyllabusComponent },

  { path: "ehm", component: EhmComponent },
  { path: "ehm/ICT2018", component: ICT2018Component },

  { path: "smart", component: SmartIntComponent },
  { path: "hi", component: HiComponent },

  { path: "demo", component: DemoComponent },
  { path: "volunteer", component: VolunteerComponent },
  { path: "activity", component: ActivityComponent },

  { path: "course", component: CourseComponent },
  { path: "under-construction", component: UnderConstructionComponent },

  { path: "**", redirectTo: "home", pathMatch: "full" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
