import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSidenavModule, MatToolbarModule } from '@angular/material';
import { MatBadgeModule } from '@angular/material/badge';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { EventsComponent } from './events/events.component';
import { EhmComponent } from './ehm/ehm.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { DemoComponent } from './demo/demo.component';
import { SixArtsComponent } from './six-arts/six-arts.component';
import { SixArtsCourseDetailComponent } from './six-arts/six-arts-course-detail/six-arts-course-detail.component';
import { TrainingComponent } from './training/training.component';
import { TrainingSyllabusComponent } from './training/training-syllabus/training-syllabus.component';
import { TrainingRecordComponent } from './training/training-record/training-record.component';
import { TrainingRecordDetailComponent } from './training/training-record-detail/training-record-detail.component';
import { TrainingGuidanceDocComponent } from './training/training-guidance-doc/training-guidance-doc.component';
import { SmartIntComponent } from './smart-int/smart-int.component';
import { ConsultancyComponent } from './smart-int/consultancy/consultancy.component';
import { ProjectIdeasComponent } from './smart-int/project-ideas/project-ideas.component';
import { ICT2018Component } from './ehm/ict2018/ict2018.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { HiComponent } from './hi/hi.component';
import { SixArtsManagementComponent } from './six-arts/six-arts-management/six-arts-management.component';
import { VolunteerComponent } from './volunteer/volunteer.component';
import { SixArtsCourseEditComponent } from './six-arts/six-arts-management/six-arts-course-edit/six-arts-course-edit.component';
import { SixArtsCourseAddComponent } from './six-arts/six-arts-management/six-arts-course-add/six-arts-course-add.component';
import { SixArtsCoursesService } from './six-arts/six-arts-courses.service';
import { UploadService} from './six-arts/upload.service';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AuthComponent } from './auth/auth.component';
import { WhoAreComponent } from './smart-int/who-are/who-are.component';
import { CovidComponent } from './smart-int/covid/covid.component';
import { EhmInfoComponent } from './ehm/ehm-info/ehm-info.component';
import { CourseComponent } from './course/course.component';
import { ActivityComponent } from './activity/activity.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EventsComponent,
    EhmComponent,
    FooterComponent,
    NavbarComponent,
    DemoComponent,
    SixArtsComponent,
    SixArtsCourseDetailComponent,
    TrainingComponent,
    TrainingSyllabusComponent,
    TrainingRecordComponent,
    TrainingRecordDetailComponent,
    TrainingGuidanceDocComponent,
    SmartIntComponent,
    ConsultancyComponent,
    ProjectIdeasComponent,
    ICT2018Component,
    UnderConstructionComponent,
    HiComponent,
    SixArtsManagementComponent,
    VolunteerComponent,
    SixArtsCourseEditComponent,
    SixArtsCourseAddComponent,
    AuthComponent,
    WhoAreComponent,
    CovidComponent,
    EhmInfoComponent,
    CourseComponent,
    ActivityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFirestoreModule,
    FormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatBadgeModule,
    AngularFireModule.initializeApp(environment.firebase),
    ColorPickerModule,
    AngularFireStorageModule,
    NoopAnimationsModule,
    AngularFireAuthModule,
  ],
  providers: [ SixArtsCoursesService,
    { provide: FirestoreSettingsToken, useValue: {} },{ provide: BUCKET, useValue: 'se-ngo.appspot.com' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
